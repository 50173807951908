import { Controller } from "stimulus";

export default class extends Controller {
    static targets = ["button"]

    connect() {
        this._initFacebookSdk();
    }

    _initFacebookSdk() {
        if (null != document.getElementById('facebook-jssdk')) return;
        // Facebook App ID = '183484865017669'
        window.fbAsyncInit = function () {
            FB.init({
                appId: '183484865017669',
                autoLogAppEvents: true,
                xfbml: true,
                version: 'v3.2'
            });
        };

        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.defer = true;
        script.id = 'facebook-jssdk';
        script.src = 'https://connect.facebook.net/en_US/sdk.js';

        var first = document.getElementsByTagName('script')[0];
        first.parentNode.insertBefore(script, first);
    }

    share(event) {
        event.preventDefault();
        
        var self = this;

        setTimeout(function () {
            self.buttonTarget.removeAttribute('disabled');
        }, 400);

        FB.ui({
            method: 'share',
            href: this.data.get('url')
        }, function (response) {
            console.log("FB Response", response)
        });
    }
}
