import { Controller } from "stimulus";

export default class extends Controller {
    static targets = ["button"]

    connect() {
        this._initTwitterJS()
        this._configureButton()
    }

    _initTwitterJS() {
        if (null != document.getElementById('twitter-wjs')) return;

        window.twttr = (function() {
            var twttr = window.twttr || {};

            var script = document.createElement('script');
            script.type = 'text/javascript';
            script.id = 'twitter-wjs';
            script.src = 'https://platform.twitter.com/widgets.js';

            var first = document.getElementsByTagName('script')[0];
            first.parentNode.insertBefore(script, first);

            twttr._e = [];

            twttr.ready = function (f) {
                twttr._e.push(f);
            };

            return twttr;
        })()
    }

    _configureButton() {
        this.buttonTarget.setAttribute('href', 'https://twitter.com/intent/tweet?text=' + this.data.get('text')  + '&url=' + this.data.get('url'))
    }
}
